<template>
    <div class="form-group mb-3">
        <label :for="id" class="form-label" v-if="label">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
        <select :id="id" class="form-select form-control" v-bind="$attrs" @change="updateInput">
            <option value="">--</option>
            <option
                v-for="(data, key) in options"
                :key="key"
                :value="data[optionId]"
                :selected="data[optionId] == modelValue"
            >
                {{ data[optionValue] }}
            </option>
        </select>
        <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors && errors[id]">{{ errors[id][0] }}</label>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number, Array],
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {},
            required: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: []
        },
        optionId: {
            type: String,
            default: 'id'
        },
        optionValue: {
            type: String,
            default: 'name'
        }
    },
    setup(props, { emit }) {
        const updateInput = (event) => {
            emit('update:modelValue', event.target.value);
        }

        return {
            updateInput
        }
    }
})
</script>