import axios from "axios";
import { ref } from "vue";

export default function scrapejobs() {
    const scrapeJobs = ref([]);

    const getScrapeJobs = async (page) => {
        let response = await axios.get(`public/get-scrape-jobs`);
        scrapeJobs.value = response.data.data;
        page.isLoading = false;
    }
    
    return {
        scrapeJobs,
        getScrapeJobs
    }
}