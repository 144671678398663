import axios from "axios";
import { ref } from "vue";

export default function jobRepo() {
    
    const jobs = ref([]);

    const getJobs = async () => {
        let response = await axios.get(`public/get-jobs`);
        jobs.value = response.data.data;
    }
    
    return {
        jobs,
        getJobs
    }
}