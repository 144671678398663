import axios from "axios";
import { ref } from "vue";

export default function globalRepo() {
    
    const plan = {
        free: {
            job_posts: 3,
            promoted: 0,
            price: {
                monthly: "Free",
                annually: "Free"
            }
        },
        pro: {
            job_posts: 5,
            promoted: 3,
            price: {
                monthly: "$297",
                annually: "$2,847"
            }
        },
        premium: {
            job_posts: "Unlimited",
            promoted: 20,
            price: {
                monthly: "$447",
                annually: "$4,047"
            }
        }
    }

    const schedules = [
        { id: 1, name: '8Hr Shift' },
        { id: 2, name: '10Hr Shift' },
        { id: 3, name: '12Hr Shift' },
        { id: 4, name: 'Weekend Availability' },
        { id: 5, name: 'Day Shift' },
        { id: 6, name: 'Night Shift' },
        { id: 7, name: 'Overtime' }
    ];
    
    return {
        plan,
        schedules
    }
}