<template>
  <div>
    <main class="main-content">
      <!--== Start Page Header Area Wrapper ==-->
      <div class="page-header-area sec-overlay sec-overlay-black bannerStyle" data-bg-img="assets/img/photos/bg2.webp">
        <div class="container pt--0 pb--0">
          <div class="row">
            <div class="col-lg-6 offset-lg-3 col-sm-12">
              <div class="login-register-form-wrap">
                <div class="login-register-form">
                  <div class="form-title">
                    <h4 class="title">Sign In</h4>
                  </div>
                  <form action="#">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <BaseInput v-model="form.email" type="email" placeholder="Email" is-required />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <BaseInput v-model="form.password" type="password" placeholder="Password" is-required />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <div class="remember-forgot-info">
                            <div class="remember">
                              <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                              <label class="form-check-label" for="defaultCheck1">Remember me</label>
                            </div>
                            <div class="forgot-password">
                              <a href="#/">Forgot Password?</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <button type="button" class="btn-theme">Sign In</button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="login-register-form-info">
                    <p>
                      Don't you have an account?
                      <router-link :to="{ name: 'Register' }">Register</router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BaseInput from "@/components/modules/form/BaseInput"

export default {
  name: 'Login',
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      loggingIn: true,
      errors: {},
      user: {}
    }
  },
  components: {
    BaseInput
  },
  methods: {
    ...mapActions({
      signIn: "adminauth/signIn"
    }),
    submit() {
      this.loggingIn = false
      this.signIn(this.form)
        .then(() => {
          window.location.href = '/admin/dashboard';
        })
        .catch(error => {
          this.loggingIn = true
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
          }
        });
    }
  }
}
</script>

<style>
.show {
  display: block !important;
}
</style>