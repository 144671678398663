import Login from '@/views/applicants/auth/Login.vue';
import Register from '@/views/applicants/auth/Register.vue';

const routes = [
    {
        path: '/login',
        name: "Login",
        component: Login
    },
    {
        path: '/registration',
        name: "Register",
        component: Register
    }
]

export default routes  