<template>
    <div id="home-footer">
        <footer class="footer-area">
            <div class="footer-main">
            <div class="container pt--0 pb--0">
                <div class="row">
                <div class="col-md-6 col-lg-3">
                    <div class="widget-item widget-about">
                        <h4 class="widget-title">About Simphini</h4>
                    <div class="widget-logo-area">
                        <a href="index.html">
                            <img class="logo-main" :src="`${header.base_url}simphini-logo-footer.png`" alt="Logo" />
                        </a>
                    </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-md-12 col-lg-12">
                            <div class="row">
                                <div class="col-md-3 col-lg-3">
                                    <div class="widget-item nav-menu-item1">
                                        <h4 class="widget-title">Job Seekers</h4>
                                        <h4 class="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-1">Job Seekers</h4>
                                        <div id="widgetId-1" class="collapse widget-collapse-body">
                                            <div class="collapse-body">
                                                <div class="widget-menu-wrap">
                                                    <ul class="nav-menu">
                                                        <li><a :href="`${header.private_base_url}/candidate/jobs`">Job Search</a></li>
                                                        <li><a :href="`${header.private_base_url}/candidate/login`">Job Seekers Login</a></li>
                                                        <li><a :href="`${header.private_base_url}/candidate/settings/profile/upload-resume`">Upload Resume</a></li>
                                                        <li><a :href="`${header.private_base_url}/candidate/jobs`">Free Job Alert</a></li>
                                                        <li><a :href="`${header.private_base_url}/candidate/jobs`">Find Companies</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="widget-item nav-menu-item1">
                                        <h4 class="widget-title">Employers</h4>
                                        <h4 class="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-2">Employers</h4>
                                        <div id="widgetId-2" class="collapse widget-collapse-body">
                                            <div class="collapse-body">
                                                <div class="widget-menu-wrap">
                                                    <ul class="nav-menu">
                                                        <li><a :href="`${header.private_base_url}/employer/login`">Employer Login</a></li>
                                                        <li><a :href="`${header.private_base_url}/employer/jobs`">Job Posting</a></li>
                                                        <li><a :href="`${header.private_base_url}/employer/applicants/find`">Find Applicants</a></li>
                                                        <li><a :href="`${header.private_base_url}/employer/settings/users`">Join Recruiters</a></li>
                                                        <li><a :href="`${header.private_base_url}/employer/settings/config/billing`">Buy Upgrade</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="widget-item nav-menu-item1">
                                        <h4 class="widget-title">Stay Connected</h4>
                                        <h4 class="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-3">Stay Connected</h4>
                                        <div id="widgetId-3" class="collapse widget-collapse-body">
                                            <div class="collapse-body">
                                                <div class="widget-menu-wrap">
                                                    <ul class="nav-menu">
                                                        <li><a href="https://www.facebook.com/Simphini-111740168311645" target="_blank">Facebook</a></li>
                                                        <li><a href="https://www.instagram.com/simphini1/" target="_blank">Instagram</a></li>
                                                        <li><a href="https://twitter.com/simphini" target="_blank">Twitter</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3">
                                    <div class="widget-item nav-menu-item1">
                                        <h4 class="widget-title">Legal</h4>
                                        <h4 class="widget-collapsed-title collapsed" data-bs-toggle="collapse" data-bs-target="#widgetId-4">Legal</h4>
                                        <div id="widgetId-4" class="collapse widget-collapse-body">
                                            <div class="collapse-body">
                                                <div class="widget-menu-wrap">
                                                    <ul class="nav-menu">
                                                        <li><a :href="`${header.private_base_url}/help/support`">Support</a></li>
                                                        <li><a :href="`${header.private_base_url}/help/`">Help Center</a></li>
                                                        <li><a :href="`${header.private_base_url}/help/feedback`">Feedback</a></li>
                                                        <li><a href="/privacy">Privacy</a></li>
                                                        <li><a href="/terms-services">Terms of Service</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="footer-bottom">
            <div class="container pt--0 pb--0">
                <div class="row">
                <div class="col-12">
                    <div class="footer-bottom-content">
                    <p class="copyright">© 2022 Simphini. Made with <i class="icofont-heart"></i> by <a target="_blank" href="https://saperemarketing.com/">Sapere Marketing.</a></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </footer>
        <div id="scroll-to-top" class="scroll-to-top"><span class="icofont-rounded-up"></span></div>
        <aside class="off-canvas-wrapper offcanvas offcanvas-start" tabindex="-1" id="AsideOffcanvasMenu" aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
            <h1 class="d-none" id="offcanvasExampleLabel">Aside Menu</h1>
            <button class="btn-menu-close" data-bs-dismiss="offcanvas" aria-label="Close">menu <i class="icofont-simple-left"></i></button>
            </div>
            <div class="offcanvas-body">
            <div class="mobile-menu-items">
                <ul class="nav-menu">
                    <li><router-link :to="{ name: 'Home' }"><span>Home</span></router-link></li>
                    <li><a href="/jobs/list"><span>Upload your resume</span></a></li>
                    <li><router-link :to="{ name: 'JobList' }"><span>Find Jobs</span></router-link></li>
                    <li class="d-block d-md-none"><a :href="`${header.candidate_login}`"><span>Apply Now</span></a></li>
                    <li class="d-block d-md-none"><a :href="`${header.candidate_profile}`"><span>Upload Your CV</span></a></li>
                </ul>
            </div>
            </div>
        </aside>
    </div>
</template>
<script>
import { defineComponent, reactive } from 'vue'

export default defineComponent({
    setup() {
        const header = reactive({
            base_url: process.env.VUE_APP_UPCLOUD_ASSETS+'/assets/public/',
            private_base_url: process.env.VUE_APP_PRIVATE_BASE_URL
        });

        return {
            header
        }
    }
})
</script>
<style scoped>
    .footer-area .widget-logo-area {
        margin: 0;
    }
    #home-footer h4.widget-title {
        font-size: 18px;
        font-family: 'Montserrat', Arial;
        margin-bottom: 20px;
    }
    .footer-main {
        background-color: #2c3655;
        padding: 70px 0 35px;
    }
    .footer-bottom {
        background: #625df5;
        font-family: 'Montserrat', Arial;
    }
    .footer-bottom .copyright i, .footer-bottom .copyright a {
        color: #FFF;
    }
    .widget-about p {
        color: #fff;
        font-family: 'Merriweather', serif;
        font-size: 12px;
        margin-top: 20px;
    }
    .footer-area .nav-menu li a {
        color: #ffffff;
        font-size: 14px;
        line-height: 22px;
        padding: 0 0;
        display: block;
        font-family: 'Montserrat', Arial;
    }
    body #app .footer-bottom-content a:hover {
        color:#fff;
        text-decoration: underline;
    }
</style>