<template>
    <div>
        <div class="form-group" :class="{ 'mb-3' : marginBottomOn }">
            <label :for="id" class="form-label" v-if="label">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
            <input 
                v-bind="$attrs"
                class="form-control" 
                :id="id" 
                :placeholder="placeholder" 
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
            />
            <label class="error jquery-validation-error small form-text invalid-feedback is-invalid" v-if="errors && errors[id]">{{ errors[id][0] }}</label>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number, Array],
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        errors: {
            type: [Object, String],
            default: {},
            required: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        marginBottomOn: {
            type: Boolean,
            default: true
        }
    }
})
</script>