<template>
    <div>
        <main class="main-content">
            <div class="page-header-area sec-overlay sec-overlay-black bannerStyle" :data-bg-img="`${upcloud_url}/assets/img/photos/bg2.webp`">
                <div class="container pt--0 pb--0">
                    <div class="row">
                        <div class="col-12">
                            <div class="job-search-wrap">
                                <div class="">
                                    <BaseInput 
                                        v-model="search"
                                        type="search"
                                        placeholder="Job title or keywords"
                                        id=""
                                        is-required
                                        class="job-search-form search-bar"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="recent-job-area recent-job-inner-area">
                <div class="container pt-5">
                    <PageLoader v-if="page.isLoading"/>
                    <div v-else class="row">
                        <div class="col-md-12 col-lg-12">                            
                            <div v-if="search" class="lead px-0">
                                Jobs containing '{{search}}'
                            </div>
                            
                            <div v-if="paginatedItems.length > 0">
                                <div class="row">
                                    <div class="col-8 align-bottom" style="padding-top: 22px;">
                                        <small>Displaying {{paginatedItems.length}} out of {{jobList.length}} jobs</small>
                                    </div>
                                    <div class="col-4">
                                        <nav aria-label="Page navigation">
                                            <ul class="pagination justify-content-end mb-3">
                                                <li 
                                                class="page-item"
                                                v-bind:class="(pagination.currentPage <= 1) ?'disabled':''">
                                                    <a class="page-link" @click="pagination.currentPage--">Previous</a>
                                                </li>
                                                <li v-for="(page, i) in pages" :key="i" class="page-item" v-bind:class="(page.isDisabled == true) ?'disabled':''">
                                                    <a class="page-link" 
                                                        @click="pagination.currentPage = page.name"
                                                        :class="{ active: isPageActive(page.name) }"
                                                        :aria-label="`Go to page number ${page.name}`">
                                                        {{ page.name }}
                                                    </a>
                                                </li>
                                                <li class="page-item" v-bind:class="(pagination.currentPage >= pagination.totalPages)? 'disabled':''">
                                                    <a class="page-link" 
                                                    @click="pagination.currentPage++">Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div class="card mb-3 shadow" v-for="(jobs, i) in paginatedItems" :key="i">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-8">
                                                <h5 class="card-title">{{jobs.position_title}}</h5>
                                                <span v-if="jobs.source == remoteok" class="">
                                                </span>
                                            </div>
                                            <div class="col-4 text-end">
                                                <a v-if="jobs.source == main" :href="`${private_base_url}/candidate/register`" target="_blank" ><button class="job-apply-now">View Job Post</button></a>
                                                <a v-else :href="`${private_base_url}/candidate/register`" target="_blank" ><button class="job-apply-now">Visit Job Post</button></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row" style="font-size:12px;">
                                            <div class="col" style="width:100px;">
                                                <span v-if="jobs.source == weworkremotely" class="">
                                                    <a target="_blank" :href="jobs.ext_url">We Work Remotely</a>
                                                </span>
                                                <span v-if="jobs.source == larajobs" class="">
                                                    <a target="_blank" :href="jobs.ext_url">Larajobs</a>
                                                </span>
                                                <span v-if="jobs.source == remoteok" class="">
                                                    <a target="_blank" :href="jobs.ext_url">Remote | Ok</a>
                                                </span>
                                                <span v-if="jobs.source == main" class="">
                                                    <a target="_blank" :href="jobs.ext_url">Simphini</a>
                                                </span>
                                                <span class="ms-3 fw-light" style="font-size:11px;"> <i>Posted {{formatDate(jobs.created_at)}}</i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 align-bottom" style="padding-top: 0px;">
                                        <small>Displaying {{paginatedItems.length}} out of {{jobList.length}} jobs</small>
                                    </div>
                                    <div class="col-4">
                                        <nav aria-label="Page navigation">
                                            <ul class="pagination justify-content-end mb-3">
                                                <li 
                                                class="page-item"
                                                v-bind:class="(pagination.currentPage <= 1) ?'disabled':''">
                                                    <a class="page-link" @click="pagination.currentPage--">Previous</a>
                                                </li>
                                                <li v-for="(page, i) in pages" :key="i" class="page-item" v-bind:class="(page.isDisabled == true) ?'disabled':''">
                                                    <a class="page-link" 
                                                        @click="pagination.currentPage = page.name"
                                                        :class="{ active: isPageActive(page.name) }"
                                                        :aria-label="`Go to page number ${page.name}`">
                                                        {{ page.name }}
                                                    </a>
                                                </li>
                                                <li class="page-item" v-bind:class="(pagination.currentPage >= pagination.totalPages)? 'disabled':''">
                                                    <a class="page-link" 
                                                    @click="pagination.currentPage++">Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <p class="lead text-center">
                                    Sorry, we couldn't find any results.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </main>
    </div>
</template>

<script>
import BaseInput from "@/components/modules/form/BaseInput"
import BaseSelect from "@/components/modules/form/BaseSelect"
import Search from "@/components/partials/Search"
import scrapejobs from '@/repositories/scrapejobs';
import { defineComponent, reactive, onMounted, ref, computed } from 'vue';
import { useRoute } from 'vue-router'

export default {
    name: 'JobList',
    components: {
        BaseInput, 
        BaseSelect, 
        Search
    },
    props: {
        searchResult: Object
    },
    setup() {
        const route = useRoute();
        const { getScrapeJobs, scrapeJobs } = scrapejobs();
        const page = reactive({
            isLoading: true,
            search: ''
        });

        const pagination = reactive({
            currentPage: 1,
            perPage: 25,
            totalPages: computed(() =>
                Math.ceil(jobList.value.length / pagination.perPage)
            ),
            maxVisibleButtons: 3
        });

        const search = ref('')
        const upcloud_url = process.env.VUE_APP_UPCLOUD_ASSETS
        const remoteok = process.env.VUE_APP_SOURCE_REF_REMOTEOK
        const larajobs = process.env.VUE_APP_SOURCE_REF_LARAJOBS
        const weworkremotely = process.env.VUE_APP_SOURCE_REF_WEWORKREMOTELY
        const main = process.env.VUE_APP_SOURCE_REF_SIMPHINI
        const private_base_url = process.env.VUE_APP_PRIVATE_BASE_URL

        onMounted( async () => {
            await getScrapeJobs(page);

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const uri = urlParams.get('search')
            search.value = uri
        });

        const jobList = computed (()=>{
            if(search.value){
                return scrapeJobs.value.filter(c => c.position_title.toLowerCase().includes(search.value.toLowerCase()))
            }else if(route.params.keyword){
                let searchKey = route.params.keyword
                return scrapeJobs.value.filter(c => c.position_title.toLowerCase().includes(searchKey.toLowerCase()))
            }else{
                return scrapeJobs.value
            }
        })

        const paginatedItems = computed(() => {
            const { currentPage, perPage } = pagination;
            const start = (currentPage - 1) * perPage;
            const stop = start + perPage;

            return jobList.value.slice(start, stop);
        });

        const startPage = computed(() => {
            const { currentPage, perPage, totalPages, maxVisibleButtons } = pagination;
            if (currentPage === 1) {
                return 1;
            }
            if (currentPage === totalPages) { 
                return totalPages - maxVisibleButtons + 1;
            }
            return currentPage - 1;
        })

        const endPage = computed(() => {
            const { totalPages, maxVisibleButtons } = pagination;
            return Math.min(startPage.value + maxVisibleButtons - 1, totalPages);
        })

        const pages = computed(() => {
            const range = [];
            for (let i = startPage.value; i <= endPage.value; i+= 1 ) {
                if(i != 0){
                    range.push({
                        name: i,
                        isDisabled: i === pagination.currentPage 
                    });
                }
            }
            return range.filter(data => data.name != 0);
        })

        const isPageActive = (page) => {
            return pagination.currentPage === page;
        }

        const jsonDecoder = (json) => {
            const data = JSON.parse(json)
            return data
        }

        const formatDate = (date) => {
            const options = { year: 'numeric', month: 'long', day: 'numeric' }
            return new Date(date).toLocaleDateString('en', options)
        }

        return {
            upcloud_url,
            page,
            scrapeJobs,
            jsonDecoder,
            formatDate,
            jobList,
            remoteok,
            larajobs,
            weworkremotely,
            main,
            private_base_url,
            search,
            pagination,
            paginatedItems,
            startPage,
            endPage,
            pages,
            isPageActive
        }
    }
}
</script>
<style scoped>
    .page-header-area {
        padding: 123px 0 114px!important;
    }
    button.job-apply-now {
        border: none;
        font-family: 'Montserrat', Arial;
        font-weight: normal;
        font-size: 13px;
        padding: 5px 13px;
        border-radius: 3px;
        background: #625df5;
        color: #fff;
        float: right;
    }
</style>