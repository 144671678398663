<template>
    <div id="nav" class="wrapper">
        <NavbarHeader />
        <router-view />
        <Footer />
    </div>
</template>
<style lang="scss">
    @import 'assets/scss/variables.scss';
    @import 'assets/scss/global.scss';
</style>
<script>
import { reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import NavbarHeader from '@/components/partials/NavbarHeader';
import Footer from '@/components/partials/Footer';

export default {
    setup() {
        const router = useRouter();
        const page = reactive({
            current_route: router.currentRoute.value
        });

        onMounted(()=>{
        });

        return {
            router,
            page
        }
    },
    components: {
        NavbarHeader,
        Footer
    }
}
</script>