<template>
  <div id="home-navbar">
    <!--== Start Header Wrapper ==-->
    <header class="header-area">
      <div class="container no-gutters">
        <div class="row no-gutter align-items-center position-relative">
          <div class="col-12">
            <div class="header-align">
              <div class="header-align-start">
                <div class="header-logo-area">
                  <a href="/">
                    <h2 class="title">
                      <img class="logo-main" :src="`${header.base_url}simphini-logo-header.png`" alt="Logo" />
                    </h2>
                  </a>
                </div>
              </div>
              <div class="header-align-center navbarMenus">
                <div class="header-navigation-area position-relative">
                  <ul class="main-menu nav">
                    <li>
                      <router-link :to="{ name: 'Home' }"><span>Home</span></router-link>
                    </li>
                    <li>
                      <router-link :to="{ name: 'JobList' }">Find Jobs</router-link>
                    </li>
                    <li>
                      <a :href="`${header.employer_login}`">Employers</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="header-align-end">
                <div class="header-action-area">
                  <div class="header-button d-none d-md-block">
                    <a :href="`${header.candidate_login}`" class="btn-registration btn-outline">Apply Now</a>
                    <a :href="`${header.candidate_profile}`" class="btn-registration btn-upload-cv">Upload Your CV</a>
                  </div>
                  <button class="btn-menu" type="button" data-bs-toggle="offcanvas" data-bs-target="#AsideOffcanvasMenu"
                    aria-controls="AsideOffcanvasMenu">
                    <i class="icofont-navigation-menu"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import { defineComponent, reactive } from 'vue'

export default defineComponent({
  setup() {
    const header = reactive({
      base_url: process.env.VUE_APP_UPCLOUD_ASSETS + '/assets/public/',
      employer_login: `${process.env.VUE_APP_PRIVATE_BASE_URL}/employer/login`,
      candidate_login: `${process.env.VUE_APP_PRIVATE_BASE_URL}/candidate/login`,
      candidate_profile: `${process.env.VUE_APP_PRIVATE_BASE_URL}/candidate/settings/profile`,
    });

    return {
      header
    }
  }
})
</script>
<style scoped>
header.header-area {
  padding: 25px 15px 15px;
  @media (min-width: $medium;) {
    padding: 25px 0 15px;
  }
}

.header-action-area .btn-registration {
  height: 43px;
  width: 150px;
  line-height: 40px;
}

a.btn-registration.btn-outline {
  border: 2px solid #625df5;
  background: transparent;
  color: #625df5;
}

.header-navigation-area .main-menu.nav>li>a {
  color: #181818;
  font-weight: 600;
}

a.btn-registration.btn-upload-cv {
  background: #625df5;
  border: 2px solid #625df5;
  margin-left: 16px;
}

a.btn-registration.btn-upload-cv:hover {
  color: white !important;
}

.navbarMenus {
  right: 30%;
}

.header-navigation-area .main-menu.nav>li>a.router-link-exact-active {
  color: #625df5;
}
</style>
