<template>
    <div class="job-search-wrap">
        <div class="job-search-form">
            <div class="row row-gutter-10">
                <div class="col-lg-auto col-sm-6 col-12 flex-grow-1">
                    <div class="form-group">
                        <BaseInput 
                            v-model="formSearch.what"
                            type="text"
                            placeholder="Job title, keywords, or company"
                            id="what"
                            is-required
                        />
                    </div>
                </div>
                <div class="col-lg-auto col-sm-6 col-12 flex-grow-1">
                    <div class="form-group">
                        <BaseSelect
                            v-model="formSearch.where"
                            id="where"
                            :options="this.whereOptions"
                        />
                    </div>
                </div>
                <div class="col-lg-auto col-sm-6 col-12 flex-grow-1">
                    <div class="form-group">
                        <button type="button" class="btn-form-search" @click="searchJob()">
                            <i class="icofont-search-1"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseInput from "@/components/modules/form/BaseInput";
import BaseSelect from "@/components/modules/form/BaseSelect";
import axios from "axios";

export default {
    name: 'JobSearch',
    components: {
        BaseInput, 
        BaseSelect
    },
    props: {
        activeSearch: false
    },
    data () {
        return {
            formSearch: {
                what: '', 
                where: '',
            },
            whereOptions: [
                { id: 2, name: 'New York', isSelected: false },
                { id: 3, name: 'California', isSelected: false },
                { id: 4, name: 'Illinois', isSelected: false },
                { id: 5, name: 'Texas', isSelected: false },
                { id: 6, name: 'Florida', isSelected: false }
            ],
            jobLists: [], 
            jobSearchResult: [],
            showJobSearchResult: false,
            selectedJob: null 
        }
    }, 
    methods: {
        async showJobDetails (id) {
                let job = this.jobLists.filter(c => c.id === id);
                this.selectedJob = job[0];

                this.jobLists.forEach((value, index) => {
                    if (value.id == id)  {
                        this.jobLists[index].selected = true;
                    } else {
                        this.jobLists[index].selected = false;
                    }
                });
        }, 
        selectJobList () {
            this.$emit('jobs', this.jobLists);
            this.selectedJob = this.jobLists[0];
            this.jobLists[0].selected = true;
            this.$emit('selectedJob', this.selectedJob);
        }, 
        searchJob () {
            this.jobSearchResult = [];
            this.searchWhat = [];
            this.searchWhere = [];

            if (this.formSearch.what && this.formSearch.where ) 
            {
                this.jobLists.filter(job => {
                    if (job.position.toLowerCase().includes(this.formSearch.what)
                        && job.addressId == this.formSearch.where) {
                        this.jobSearchResult.push(job);
                    }
                });
            }
            else if (this.formSearch.what && !this.formSearch.where) 
            {
                this.jobLists.filter(job => {
                    if (job.position.toLowerCase().includes(this.formSearch.what)) {
                        this.jobSearchResult.push(job);
                    }
                });
            } 
            else if (!this.formSearch.what && this.formSearch.where) 
            {
                this.jobLists.filter(job => {
                    if (job.addressId == this.formSearch.where) {
                        this.jobSearchResult.push(job);
                    }
                });
            }

            // this.jobSearchResult = this.tempJobSearchResult;

            this.showJobSearchResult = true;
            this.$emit('jobs', this.jobSearchResult);
            this.jobSearchResult[0].selected = true;
            this.$emit('selectedJob', this.jobSearchResult[0]);
        }, 
    },
    mounted () {
        axios.get(`public/get-jobs`).then(response => {
            this.jobLists = response.data.data;
            this.selectJobList();
        });
    }
}
</script>