<template>
    <div class="container d-flex flex-column">
        <div class="row h-100">
            <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                <div class="d-table-cell align-middle" style="height: auto;">
                    <div class="text-center spinner-container">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .spinner-container { font-size: 40px; }
</style>