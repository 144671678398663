import { createRouter, createWebHistory } from 'vue-router'
import applicantsRoute from './applicants/index';
import jobsRoute from './jobs/index';
// import Home from '../views/Home.vue'
// import store from "@/store";

const routes = [
  {
    path: '/',
    name: "Home",
    component: () => import("../views/frontpage/FrontPage.vue"),
  }, 
  {
    path: '/terms-services',
    name: "TermsServices",
    component: () => import("../views/TermsServices.vue"),
  },
  {
    path: '/privacy',
    name: "PrivacyPolicy",
    component: () => import("../views/Privacy.vue"),
  }, 
  {
    path: '/plans',
    name: "PlanSelection",
    component: () => import("../views/plans/Index.vue"),
  }, 
  ...applicantsRoute,
  ...jobsRoute
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
