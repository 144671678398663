import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";


// import Modernizr from "@modernizr";

require('/public/assets/css/bootstrap.min.css');
require('/public/assets/css/icofont.css');
require('/public/assets/css/swiper.min.css');
require('/public/assets/css/fancybox.min.css');
require('/public/assets/css/aos.min.css');
require('/public/assets/css/style.css');

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

/** Componenets **/
import PageLoader from '@/components/modules/PageLoader.vue';

const app = createApp(App)
    app.use(store)
    app.use(router)
    // app.use(Modernizr)
    app.mount('#app')
    app.component('PageLoader', PageLoader)

import "bootstrap"
// import "modernizr"
import "swiper"
import "fancybox"
import "aos"
// import "counterup"
// import "waypoints"
// import "popper"
// import "font-awesome"
// createApp(App).use(store).use(router).mount('#app')
