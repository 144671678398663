import JobList from '@/views/jobs/List.vue';
import JobDetails from '@/views/jobs/JobDetails.vue';
import ScrapeJobsList from '@/views/jobs/ScrapeJobsList.vue';

const routes = [
    {
        path: '/jobs/list',
        name: "JobList",
        component: ScrapeJobsList
    },
    {
        path: '/jobs/details/:id',
        name: "JobDetails",
        component: JobDetails
    },
    {
        path: '/jobs/list/:keyword',
        name: "JobListSearch",
        component: ScrapeJobsList
    }
]

export default routes  